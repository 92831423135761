<template>
  <el-card class="card-service">
    <div class="box-img" :style="`background-image:url(` + illustration + `);`">
      <!-- <div class="logo-in-card" v-if="dataService.logo">
        <img :src="`${urlService}${dataService._id}/${dataService.logo}`" />
      </div> -->
    </div>
    <div class="box-detail-service">
      <div class="box-under-inside">
        <small class="small-name">ชื่อศูนย์บริการ </small>
        <p class="name-service">
          <el-tooltip
            class="item"
            effect="dark"
            :content="dataService.name"
            placement="bottom"
          >
            <span>{{ dataService.name }}</span>
          </el-tooltip>
        </p>
        <el-tooltip
          class="item"
          effect="dark"
          :content="
            dataService.address +
            ' ต.' +
            dataService.subDistrict +
            ' อ.' +
            dataService.district +
            ' จ.' +
            dataService.province +
            ' ' +
            dataService.postcode
          "
          placement="bottom"
        >
          <p class="color-gray d-flex">
            <span class="left-icon">
              <img src="../assets/image/icon/map.svg" alt />
            </span>
            <span class="address">
              {{
                dataService.address +
                " ต." +
                dataService.subDistrict +
                " อ." +
                dataService.district +
                " จ." +
                dataService.province +
                " " +
                dataService.postcode
              }}
            </span>
          </p>
        </el-tooltip>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/phone.svg" alt />
          </span>
          <span v-if="typeof dataService.phone == 'string'">{{
            dataService.phone
          }}</span>
          <span v-else-if="dataService.phone == ''">-</span>
          <span v-else>
            <span v-for="(phone, index) in dataService.phone" :key="index">
              {{ phone }}{{ index == dataService.phone.length - 1 ? "" : ", " }}
            </span>
          </span>
        </p>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/messengeCard.svg" alt />
          </span>
          <span>
            <a :href="'mailto:' + dataService.email" target="_blank">
              {{ dataService.email == "" ? "-" : dataService.email }}
            </a>
          </span>
        </p>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/website.svg" alt />
          </span>
          <span>
            <a :href="dataService.website" target="_blank">
              {{ dataService.website }}
            </a>
          </span>
        </p>
        <p class="color-gray d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/clock.svg" alt />
          </span>
          <span>
            <div
              v-for="(item, index) in getOpenDay(dataService.openDay)"
              :key="index"
            >
              {{ item }}
            </div>
          </span>
        </p>
        <p class="color-gray d-flex">
          <span class="left-icon">
            <img
              src="../assets/image/icon/hospital1.svg"
              style="max-width: 24px"
              alt
            />
          </span>
          <span class="address">
            {{ dataService.serviceName + " " + dataService.departmentName }}
          </span>
        </p>
        <div class="group-seemap">
          <button type="button" class="el-button see-map el-button--text">
            <span class="left-icon">
              <img src="../assets/image/icon/seemap.svg" alt />
            </span>
            <!-- 'https://maps.google.com/maps?q=' + -->
            <a
              class="no-underline" 
              :href="'https://www.google.com/maps/search/?api=1&query='+ replaceData(dataService.nameTH)+','+
                  replaceData(dataService.address)+','+ 
                  dataService.lat +
                  ',' +
                  dataService.lng +
                  '&hl=th&z=15&amp;output=embed'"
              target="_blank"
              >ดูในแผนที่ </a
            >
          </button>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  props: ["dataService", "typeclinics"],
  data() {
    return {
      urlService: process.env.VUE_APP_BASE_URL_GET,
    };
  },
  computed: {
    illustration() {
      if (this.dataService.illustration) {
        return `${this.urlService}service/${this.dataService.serviceId}/${this.dataService.illustration}`;
      } else {
        return "https://staging-mdc.robinson.co.th/media/aw_rbslider/slides/default-image_7.png";
      }
    },
  },
  methods: {
     replaceData(data){
      if(typeof data != 'undefined'){
        let str = data.replace(' ','+')
        return str
      }else{
        return ""
      }
    },
    getOpenDay(days) {
      let allDay = days;
      let dustanceDay = [];
      days.map((item) => {
        let match = allDay.filter(
          (filter) => filter.open == item.open && filter.close == item.close
        );

        if (match.length > 0) {
          let day =
            match.length == 1
              ? `${match[0].day}`
              : `${match[0].day} - ${match[match.length - 1].day}`;

          if (match.length == 7) {
            dustanceDay.push(`ทุกวัน ${match[0].open} - ${match[0].close}`);
            dustanceDay.push("ไม่เว้นวันหยุดราชการ");
          } else {
            dustanceDay.push(`${day} ${match[0].open} - ${match[0].close}`);
          }
        }

        let notMatch = allDay.filter(
          (filter) => filter.open != item.open || filter.close != item.close
        );

        allDay = notMatch;
      });

      if (this.dataService.closedOnPubilcHoliday) {
        dustanceDay.push("ปิดวันนักขัตถฤษ์");
      }

      return dustanceDay;
    },
  },
};
</script>
