<template>
  <div id="service" class="content">
    <div class="search-service">
      <div class="container">
        <p class="title-inside">ค้นหาบริการทางการแพทย์</p>
        <div class="group-search">
          <el-dropdown
            trigger="click"
            class="service-all group-mobile"
            :placement="'bottom-start'"
            ref="mySelect1"
          >
            <span class="el-dropdown-link">
              บริการทั้งหมด
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown">
              <div class="select-service">
                <p class="name-ingroup">งานบริการ</p>
                <el-radio-group
                  v-model="selectService1"
                  style="width: 100%"
                  @change="search"
                >
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      :sm="6"
                      :md="4"
                      :lg="3"
                      v-for="(dataServicesAll, index) in symptomList"
                      :key="index"
                    >
                      <el-radio-button
                        :label="dataServicesAll.value"
                        style="width: 100%"
                      >
                        <img
                          class="icon-select"
                          :src="
                            require(`@/assets/image/serviceall/service${
                              index + 1
                            }.svg`)
                          "
                        />
                        <p>{{ dataServicesAll.value }}</p>
                      </el-radio-button>
                    </el-col>
                  </el-row>
                </el-radio-group>
                <p class="name-ingroup">งานนวัตกรรม</p>
                <el-radio-group
                  class="two-box"
                  v-model="selectInnovation"
                  style="width: 100%"
                  @change="goInnovation"
                >
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      :sm="6"
                      :md="4"
                      :lg="3"
                      v-for="(dataServicesAll, index) in innovationList"
                      :key="index"
                    >
                      <el-radio-button
                        :label="dataServicesAll.value"
                        style="width: 100%"
                      >
                        <img
                          class="icon-select"
                          :src="
                            require(`@/assets/image/innovationall/innovation${
                              index + 1
                            }.svg`)
                          "
                        />
                        <p>{{ dataServicesAll.value }}</p>
                      </el-radio-button>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
              <div class="tab-clear text-right">
                <span @click="searchClinic(9999)">ล้างการค้นหา</span>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input
            placeholder="ค้นหาบริการ โรค หรืออาการ"
            class="no-radius-right"
            v-model="selectService"
            @change="changeInput()"
            @keyup.13.native="search()"
          >
            <img
              src="../assets/image/icon/search.svg"
              alt
              class="el-icon-edit el-input__icon"
              slot="prefix"
            />
            <template slot="prepend">
              <el-dropdown
                trigger="click"
                class="service-all"
                :placement="'bottom-start'"
                ref="mySelect"
              >
                <span class="el-dropdown-link">
                  บริการทั้งหมด
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="custom-dropdown">
                  <div class="select-service">
                    <p class="name-ingroup">งานบริการ</p>
                    <el-radio-group
                      v-model="selectService1"
                      style="width: 100%"
                      @change="search"
                    >
                      <el-row :gutter="10">
                        <el-col
                          :span="12"
                          :sm="6"
                          :md="4"
                          :lg="3"
                          v-for="(dataServicesAll, index) in symptomList"
                          :key="index"
                        >
                          <el-radio-button
                            :label="dataServicesAll.value"
                            style="width: 100%"
                          >
                            <img
                              class="icon-select"
                              :src="
                                require(`@/assets/image/serviceall/service${
                                  index + 1
                                }.svg`)
                              "
                            />
                            <p>{{ dataServicesAll.value }}</p>
                          </el-radio-button>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                    <p class="name-ingroup">งานนวัตกรรม</p>
                    <el-radio-group
                      class="two-box"
                      v-model="selectInnovation"
                      style="width: 100%"
                      @change="goInnovation"
                    >
                      <el-row :gutter="10">
                        <el-col
                          :span="12"
                          :sm="6"
                          :md="4"
                          :lg="3"
                          v-for="(dataServicesAll, index) in innovationList"
                          :key="index"
                        >
                          <el-radio-button
                            :label="dataServicesAll.value"
                            style="width: 100%"
                          >
                            <img
                              class="icon-select"
                              :src="
                                require(`@/assets/image/innovationall/innovation${
                                  index + 1
                                }.svg`)
                              "
                            />
                            <p>{{ dataServicesAll.value }}</p>
                          </el-radio-button>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </div>
                  <div class="tab-clear text-right">
                    <span @click="searchClinic(9999)">ล้างการค้นหา</span>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template slot="append">
              <el-button @click="search" type=" btn-color-dark searchInput"
                >ค้นหา</el-button
              >
            </template>
          </el-input>
          <!-- <el-button @click="search" type=" btn-color-dark secondary"
            >ค้นหา</el-button
          > -->
        </div>
      </div>
    </div>
    <div v-loading="loading" class="inside-page">
      <div class="container">
        <span v-if="valClick != 0 || state == 2">
          <p class="count-search">
            <span style="padding-right: 10px" v-if="selectService != ''"
              >คำที่คุณค้นหา</span
            >
            <span class="active" v-if="this.routeParams != {}">{{
              selectService
            }}</span>
            <span class="active" v-else>{{ selectService }}</span>
          </p>
        </span>

        <span class="count-search" v-if="valClick != 0 || state == 2">
          <span v-if="selectService != ''">
            ผลลัพธ์การค้นหา
            <span class="active">{{ total }}</span> ศูนย์บริการ
          </span>
        </span>
        <el-row :gutter="30" class="set-row">
          <el-col
            :span="24"
            :sm="12"
            :md="8"
            v-for="(dataClnic, index) in clinic"
            :key="index"
            class="mt-1"
          >
            <cardService :dataService="dataClnic" />
          </el-col>
        </el-row>
      </div>
      <div v-if="total > 9" class="group-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          :page-size="pageSize"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<style  scope="scoped">
.el-input__prefix {
  left: 17px;
}
.el-input--prefix .el-input__inner {
  padding-left: 42px;
}
.name-ingroup {
  margin-top: 20px;
  font-size: 24px;
  color: #1c3f5b;
}
</style>
<script>
import cardService from "@/components/cardService";
import { HTTP } from "@/service/axios.js";

export default {
  components: {
    cardService,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    symptomList() {
      return this.$store.state.symptomList;
    },
    innovationList() {
      return this.$store.state.innovationList;
    },
  },
  data() {
    return {
      state: null,
      selectServiceInput: "",
      selectService: "",
      selectService1: "",
      selectInnovation: "",
      valClick: 0,
      service: [],
      clinic: [],
      dataClinic: "",
      page: 1,
      total: 0,
      loading: true,
      a: 0,
      b: 0,
      pageSize: 9,
    };
  },
  mounted() {
    this.setParams();
    // this.fetchService();
    this.checkState();
    this.searchClinic();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        this.selectService = this.routeParams.search;
        this.state = this.routeParams.state;
      }
    },
    checkState() {
      // if (this.routeParams.search != "") {
      //   this.dataClinic = this.routeParams.search;
      // } else {
      //   this.dataClinic = "";
      // }
      if (this.state == 1) {
        this.searchInno();
      } else if (this.state == 2) {
        this.searchClinic();
      }
    },
    searchInno() {
      console.log("asdasd");
    },
    searchClinic(val) {
      var dataSend = "";
      var dataTag = "";
      if (this.state == 2) {
        dataTag = "all";
      }
      if (this.selectService == undefined || this.selectService == "") {
        dataSend = "all";
      } else {
        dataSend = this.selectService;
      }
      let tag = this.selectService1
        ? this.symptomList.filter(
            (filter) =>
              filter.value.toLowerCase().indexOf(this.selectService1) > -1
          )
        : "";
      if (tag.length > 0) {
        dataTag = tag[0].id;
        this.loading = false;
      } else {
        dataTag = "all";
      }

      if (val == 9999) {
        this.selectService1 == "";
        dataSend = "all";
        dataTag = "all";
        var inputs = document.querySelectorAll(".el-radio-button__orig-radio");
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        this.$refs.mySelect.visible = false;
        this.$refs.mySelect1.visible = false;
      }
      HTTP.get(
        `clinics/${dataSend}/${this.pageSize}/${
          (this.page - 1) * this.pageSize
        }/${dataTag}`
      )
        .then((res) => {
          if (res.data.success) {
            console.log("fetch Clinic", res.data);
            this.clinic = res.data.obj;
            this.total = res.data.count;
            this.loading = false;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchService error", e);
        });
    },
    changeInput() {
      this.valClick = 0;
      if (this.selectService == "") {
        this.valClick = 0;
        this.searchClinic();
      }
    },

    // fetchService(val) {
    //   if (val == 1) {
    //     this.selectService = this.selectServiceInput;
    //     // this.valClick = 2;
    //   }
    //
    //   let nextPage = "";
    //   if (typeof this.selectService != "undefined") {
    //     nextPage = 0;
    //   } else {
    //     nextPage = (this.page - 1) * this.pageSize;
    //   }
    //   HTTP.get(
    //     `services/${nextPage}/${this.pageSize}/${
    //       this.selectService && tag.length == 0 ? this.selectService : "all"
    //     }/${tag.length > 0 ? tag[0].id : 0}`
    //   )
    //     .then((res) => {
    //       if (res.data.success) {
    //         console.log("fetchService", res.data.obj);
    //         this.service = res.data.obj;
    //         this.total = res.data.count.length;
    //       } else {
    //         this.alertFailError();
    //       }
    //     })
    //     .catch((e) => {
    //       this.alertCatchError(e);
    //       console.log("fetchService error", e);
    //     })
    //     .finally(() => {
    //       setTimeout(() => {
    //         this.loading = false;
    //       }, 500);
    //     });
    // },
    changePage(page) {
      this.page = page;
      this.searchClinic();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },
    search() {
      this.loading = true;
      this.searchClinic();
      this.valClick = 1;
      this.$refs.mySelect.visible = false;
      this.$refs.mySelect1.visible = false;
      this.selectServiceInput = "";
    },
    goInnovation() {
      let tag = this.selectInnovation
        ? this.innovationList.filter((filter) => {
            return filter.value.indexOf(this.selectInnovation) > -1;
          })
        : "";
      if (tag.length > 0) {
        var val = tag[0].id;
        this.$router.push(`/service/innovation?search=all&tags=${val}`);
      }
    },
  },
};
</script>
